export const menuItems = [
  {
    name: "Referenssit",
    label: "Referenssit",
    items: [
      { name: "kaidelasit", label: "Kaidelasit" },
      { name: "terassi", label: "Terassi" },
      { name: "kylpyhuone", label: "Kylpyhuone" },
      { name: "parvekelasit", label: "Parvekelasit" }, 
    ],
  },
];
