// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kaidelasit-js": () => import("./../../../src/pages/kaidelasit.js" /* webpackChunkName: "component---src-pages-kaidelasit-js" */),
  "component---src-pages-kylpyhuone-js": () => import("./../../../src/pages/kylpyhuone.js" /* webpackChunkName: "component---src-pages-kylpyhuone-js" */),
  "component---src-pages-palvelut-js": () => import("./../../../src/pages/palvelut.js" /* webpackChunkName: "component---src-pages-palvelut-js" */),
  "component---src-pages-parvekelasit-js": () => import("./../../../src/pages/parvekelasit.js" /* webpackChunkName: "component---src-pages-parvekelasit-js" */),
  "component---src-pages-terassi-js": () => import("./../../../src/pages/terassi.js" /* webpackChunkName: "component---src-pages-terassi-js" */)
}

