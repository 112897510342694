import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Section, Title, Text, Span, Box } from '../../components/Core';
import ContactForm from '../../components/ContactForm';
import { device } from '../../utils';

const ContactCard = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
  margin-top: 3rem;
`;

const Contact = ({ hero = true, bg = 'dark', ...rest }) => {
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>
          <Row className="justify-content-center">
            <Col lg="6">
              <Box className="pr-lg-5">
                <Title color="light" variant="secSm" mb="2rem">
                  Ota yhteyttä
                </Title>
                <ContactForm theme="dark" />
              </Box>
            </Col>
            <Col lg="5">
              <ContactCard className="p-5 ml-lg-5">
                <div>
                  <Text color="light">Sähköposti</Text>

                  <a href="mailto:info@lasiliukkonen.fi" className="font-weight-bold">
                    <Span color="primary">info@lasiliukkonen.fi</Span>
                  </a>
                </div>
                <div className="mt-5">
                  <Text color="light">Puhelin</Text>

                  <div>
                    <a href="tel:0451138944" className="font-weight-bold">
                      <Span color="primary">+358451138944</Span>
                    </a>
                  </div>
                </div>
              </ContactCard>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Contact;
