import React from 'react';
import { Link } from 'gatsby';

import logoLasi from '../../assets/image/svg/lasiliukkonen.svg';
import logoLasiNega from '../../assets/image/svg/lasiliukkonen_nega.svg';

const Logo = ({ color = 'front', height, className = '', showNegaLogo,  ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
        { showNegaLogo ?  <img src={logoLasiNega} /> : <img src={logoLasi} /> }
    </Link>
  );
};

export default Logo;
