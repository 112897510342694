import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { Section, Title, Text, Span, Button, Box } from '../../components/Core';
import imgPhoto from '../../assets/image/jpg/about.jpg';
import GlobalContext from '../../context/GlobalContext';

const About = ({ hero = true, bg = 'dark', ...rest }) => {
  const gContext = useContext(GlobalContext);
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div>
                <img src={imgPhoto} alt="folio" className="img-fluid" />
              </div>
            </Col>
            <Col lg="6">
              <div className="pl-lg-4 pt-5 pt-lg-0">
                <Title color="light" variant="secSm">
                  Palvelut
                </Title>
                <Text
                  color="text"
                  className="mt-3 mt-lg-3"
                  css={`
                    line-height: 1.5;
                  `}
                >
                  Lasi Liukkonen palvelee monipuolisissa lasitustöissä pääkaupunkiseudulla ja
                  Uudenmaan alueella - yli 38 vuoden kokemuksella.
                </Text>
                <Text color="text" className="mt-3">
                  Korjauslasitukset, rakennuslasitukset, terassilasitukset, parvekelasitukset,
                  saunalasitukset, kaidelasit, suihkuseinät, liukuovet, välitilat.
                </Text>

                <Box className="mt-4">
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      gContext.toggleContact();
                    }}
                  >
                    Ota yhteyttä
                  </Button>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default About;
